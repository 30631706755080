<template>
    <a-modal
        :visible="visible"
        :title="state.title"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="80%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @keydown="form.onKeydown($event)"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- code -->
            <AFormItem
                label="Kode"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                name="code"
                has-feedback>
                <ATooltip title="auto generate">
                    <AInput
                        placeholder="auto generate"
                        disabled
                        v-model:value="form.code"
                        style="width:100%;"/>
                </ATooltip>
            </AFormItem>

            <!-- title -->
            <AFormItem
                label="Judul"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="title"
                has-feedback>
                <AInput
                    placeholder="judul"
                    :disabled="form.readOnly"
                    v-model:value="form.title"
                    style="width:100%;"/>
            </AFormItem>

            <!-- description -->
            <AFormItem
                label="Deskripsi"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="description"
                has-feedback>
                <ATextarea
                    placeholder="deskripsi"
                    :disabled="form.readOnly"
                    v-model:value="form.description"
                    style="width:100%;"/>
            </AFormItem>

            <!-- regional -->
            <AFormItem
                label="Regional"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="map_id"
                has-feedback>
                <FilterRegional
                    :mode="null"
                    v-model:value="form.map_id"
                    :disabled="form.readOnly"
                    style="width:100%"/>
            </AFormItem>

            <!-- tanggal awal -->
            <AFormItem
                label="Tanggal Awal"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="start_date"
                has-feedback>
                <ADatePicker
                    v-model:value="form.start_date"
                    :disabled-date="disabledStartDate"
                    placeholder="tanggal awal"
                    style="width:100%;"
                    format="DD MMMM YYYY"/>
            </AFormItem>

            <!-- tanggal akhir -->
            <AFormItem
                label="Tanggal Akhir"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="end_date"
                has-feedback>
                <ADatePicker
                    v-model:value="form.end_date"
                    :disabled-date="disabledEndDate"
                    placeholder="tanggal akhir"
                    style="width:100%;"
                    format="DD MMMM YYYY"/>
            </AFormItem>

            <!-- active -->
            <AFormItem
                label="Status"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="active"
                has-feedback>
                <ARadioGroup
                    :disabled="form.readOnly"
                    v-model:value="form.active">
                    <ARadio :value="true">Aktif</ARadio>
                    <ARadio :value="false">Nonaktif</ARadio>
                </ARadioGroup>
            </AFormItem>

            <!-- fields -->
            <AFormItem
                label="Fields"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required>
                <section class="ant-form ant-form-vertical">
                    <article
                        v-for="(item, index) in form.fields"
                        :key="index">
                        <ARow
                            :gutter="[8,0]">
                            <ACol :span="4">
                                <AFormItem
                                    label="Name"
                                    label-align="left"
                                    :name="['fields', index, 'name']"
                                    has-feedback>
                                    <AInput
                                        placeholder="exp: full_name"
                                        :disabled="form.readOnly || item.id"
                                        v-model:value="item.name"/>
                                </AFormItem>
                            </ACol>

                            <ACol :span="5">
                                <AFormItem
                                    label="Label"
                                    label-align="left"
                                    :name="['fields', index, 'label']"
                                    has-feedback>
                                    <AInput
                                        placeholder="exp: full name"
                                        :disabled="form.readOnly || item.id"
                                        v-model:value="item.label"/>
                                </AFormItem>
                            </ACol>

                            <ACol :span="7">
                                <AFormItem
                                    label="Type"
                                    label-align="left"
                                    :name="['fields', index, 'type']"
                                    has-feedback>
                                    <FilterMasterFormType
                                        :mode="null"
                                        :disabled="form.readOnly || item.id"
                                        v-model:value="item.type"/>
                                </AFormItem>
                            </ACol>

                            <ACol :span="7">
                                <AFormItem
                                    label="Attribute"
                                    label-align="left"
                                    :name="['fields', index, 'attribute']"
                                    has-feedback>
                                    <FilterMasterFormAttribute
                                        :disabled="form.readOnly || item.id"
                                        v-model:value="item.attribute"/>
                                </AFormItem>
                            </ACol>

                            <ACol :span="1" v-if="!item.id">
                                <!-- space blank for label -->
                                <AFormItem
                                    label="‎">
                                    <APopconfirm
                                        title="Apa Anda yakin?"
                                        ok-text="Iya"
                                        cancel-text="Tidak"
                                        @confirm="removeRowField(item)">
                                        <AButton type="danger">
                                            <i class="fa fa-trash" />
                                        </AButton>
                                    </APopconfirm>
                                </AFormItem>
                            </ACol>
                        </ARow>

                        <section v-if="item.type == 'multiple'">
                            <article
                                v-for="(option, indexOption) in item.options"
                                :key="indexOption">

                                <span>Multiple {{indexOption+1}}</span>

                                <ARow :gutter="[8,4]">
                                    <ACol :span="6">
                                        <AFormItem
                                            :name="['fields', index, 'options', indexOption, 'description']"
                                            required
                                            has-feedback>
                                            <AInput
                                                placeholder="name"
                                                size="small"
                                                :disabled="form.readOnly || option.id"
                                                v-model:value="option.description"
                                                style="width:100%;"/>
                                        </AFormItem>
                                    </ACol>

                                    <ACol :span="6">
                                        <AFormItem
                                            :name="['fields', index, 'options', indexOption, 'label']"
                                            required
                                            has-feedback>
                                            <AInput
                                                placeholder="value"
                                                size="small"
                                                :disabled="form.readOnly || option.id"
                                                v-model:value="option.label"
                                                style="width:100%;"/>
                                        </AFormItem>
                                    </ACol>

                                    <ACol :span="12" v-if="!option.id">
                                        <APopconfirm
                                            title="Apa Anda yakin?"
                                            ok-text="Iya"
                                            cancel-text="Tidak"
                                            @confirm="removeRowFieldOption(item, indexOption, index)">
                                            <AButton
                                                type="danger"
                                                size="small">
                                                <i class="fa fa-trash" />
                                            </AButton>
                                        </APopconfirm>
                                    </ACol>
                                </ARow>
                            </article>

                            <!-- add option -->
                            <ARow class="form-row" type="flex" justify="start">
                                <ACol>
                                    <AFormItem>
                                        <AButton
                                            type="dashed"
                                            size="small"
                                            @click="addFieldOption(item, index)">
                                            <i class="fa fa-plus mr-2" /> Tambah Option
                                        </AButton>
                                    </AFormItem>
                                </ACol>
                            </ARow>
                        </section>
                        <!-- end for loop options field -->
                    </article>
                    <!-- end for loop fields -->
                </section>
                <!-- end custom manipulation form vertical -->
            </AFormItem>

            <!-- add field -->
            <ARow class="form-row" type="flex" justify="start">
                <ACol>
                    <AFormItem>
                        <AButton
                            type="primary"
                            @click="addField">
                            <i class="fa fa-plus mr-2" /> Tambah Field
                        </AButton>
                    </AFormItem>
                </ACol>
            </ARow>

            <ARow
                v-if="!form.readOnly"
                class="form-row mt-2"
                type="flex"
                justify="end">
                <ACol>
                    <AFormItem>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </AFormItem>
                </ACol>
            </ARow>
        </AForm>

        <div v-if="state.errors">
            <ASpace>
                <AAlert
                    v-for="(item, index) in state.errors"
                    type="error"
                    :key="index"
                    :message="item"
                    banner
                    closable
                    @close="state.errors = []" />
            </ASpace>
        </div>
    </a-modal>
</template>

<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import Form from 'vform'
import apiClient from '@/services/axios'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterMasterFormType from '@/components/filter/FilterMasterFormType'
import FilterMasterFormAttribute from '@/components/filter/FilterMasterFormAttribute'
import { message } from 'ant-design-vue'
import moment from 'moment'
import {
    hasRoles,
    ROLE_ADMIN_BK,
} from '@/helpers'

export default defineComponent({
    components: {
        FilterRegional,
        FilterMasterFormType,
        FilterMasterFormAttribute,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            code: null,
            title: null,
            description: null,
            active: false,
            fields: [],
            // other target
            master_id: null,
            map_id: null,
            start_date: null,
            end_date: null,
            target_id: null, // target_id for a mapping
        }))

        const formRef = ref()

        const state = reactive({
            title: 'Lihat Form Survey',
            endpoint: {
                store: '/api/formsurvey/master',
                update: `/api/formsurvey/master/${props.item.id}`,
                target: '/api/formsurvey/target',
            },
            rules: {
                title: [
                    {
                        required: true,
                        message: 'Judul tidak boleh kosong!',
                    },
                ],
                description: [
                    {
                        required: true,
                        message: 'Deskripsi tidak boleh kosong!',
                    },
                ],
                map_id: [
                    {
                        required: true,
                        message: 'Regional tidak boleh kosong!',
                    },
                ],
                start_date: [
                    {
                        required: true,
                        message: 'Tanggal awal tidak boleh kosong!',
                    },
                ],
                end_date: [
                    {
                        required: true,
                        message: 'Tanggal akhir tidak boleh kosong!',
                    },
                ],
                active: [
                    {
                        required: true,
                        message: 'Status tidak boleh kosong!',
                    },
                ],
                fields: [],
            },
            rule_field: {
                name: [
                    {
                        required: true,
                        message: "Name tidak boleh kosong!",
                    },
                    {
                        validator(_, value) {
                            // Periksa apakah nilai hanya mengandung huruf kecil dan underscore
                            return /^[a-z_]+$/.test(value.trim()) && value.trim() !== ""
                                ? Promise.resolve()
                                : Promise.reject("Hanya boleh huruf kecil dan underscore!")
                        },
                        trigger: "blur",
                    },
                ],
                label: [
                    {
                        required: true,
                        message: "Label tidak boleh kosong!",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "Type tidak boleh kosong!",
                    },
                ],
                options: [
                    {
                        required: true,
                        message: "Options tidak boleh kosong!",
                    },
                ],
                attribute: [
                    {
                        required: true,
                        message: "Attribute tidak boleh kosong!",
                    },
                ],
                options: [],
            },
            rule_option: {
                label: [
                    {
                        required: true,
                        message: "Value tidak boleh kosong!",
                    },
                    {
                        validator(_, value) {
                            // Periksa apakah nilai adalah boolean atau sesuai dengan regex
                            return typeof value === "boolean" || (/^[a-z0-9_]+$/.test(value.trim()) && value.trim() !== "")
                                ? Promise.resolve()
                                : Promise.reject("Hanya boleh huruf kecil, angka, atau underscore, dan boolean!");
                        },
                        trigger: "blur",
                    },
                ],
                description: [
                    {
                        required: true,
                        message: "Name tidak boleh kosong!",
                    },
                ],
            },
            field: {
                name: '',
                label: '',
                type: null,
                options: [],
                attribute: [],
            },
            field_options: {
                label: null,
                description: null,
            },
            errors: [],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const disabledStartDate = (current) => {
            return current && current > moment(form.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(form.start_date) >= current
        }

        const handleOk = async () => {

            // validation form
            await formRef.value.validate()

            if (!form.id) {
                form.post(state.endpoint.store)
                    .then(({ data }) => {
                        apiClient.post(state.endpoint.target, {
                            master_id: data.id,
                            map_id: form.map_id,
                            start_date: form.start_date,
                            end_date: form.end_date,
                        })

                        message.success('Berhasil menyimpan')
                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(({ response: { data, status } }) => {
                        if (data) {
                            state.errors = data.errors
                        }
                    })
            } else {
                form.put(state.endpoint.update)
                    .then(({ data }) => {
                        apiClient.put(`${state.endpoint.target}/${form.target_id}`, {
                            master_id: data.id,
                            map_id: form.map_id,
                            start_date: form.start_date,
                            end_date: form.end_date,
                        })

                        message.success('Berhasil memperbarui')
                        handleModalCancel()
                        emit('success', data)
                    })
                    .catch(({ response: { data, status } }) => {
                        if (data) {
                            state.errors = data.errors
                        }
                    })
            }
        }

        const addField = () => {
            if (form.fields.filter(v => v.code === null).length > 0) {
                message.warning('Pastikan field sebelumnya terisi!')
                return
            }
            
            form.fields.push(Object.assign({}, state.field))
            state.rules.fields.push(Object.assign({}, state.rule_field))
        }

        const removeRowField = (item) => {
            const index = form.fields.indexOf(item)

            if (item !== -1) {
                form.fields.splice(index, 1)
                state.rules.fields.splice(index, 1)
            }
        }

        const addFieldOption = (item, indexParent) => {
            if (item.options.filter(v => (v.description === null || v.label === null)).length > 0) {
                message.warning('Pastikan multiple sebelumnya terisi!')
                return
            }
            
            item.options.push(Object.assign({}, state.field_options))
            state.rules.fields[indexParent].options.push(Object.assign({}, state.rule_option))
        }

        const removeRowFieldOption = (item, index, indexParent) => {
            if (item !== -1) {
                item.options.splice(index, 1)
                state.rules.fields[indexParent].options.splice(index, 1)
            }
        }

        /**
         * handle func vue
         **/
        onMounted(() => {
            // reset form
            form.reset()
            form.readOnly = props.item.readOnly

            if (!form.id) {
                // default add one field
                addField()
            } else {
                form.code = props.item.code
                form.title = props.item.title
                form.description = props.item.description
                form.demand_all_brand = props.item.demand_all_brand
                form.active = props.item.active
                form.fields = props.item.fields ?? []

                if (props.item.target) {
                    form.target_id = props.item.target[0]?.id
                    form.map_id = props.item.target[0]?.map_id
                    form.start_date = props.item.target[0]?.start_date
                    form.end_date = props.item.target[0]?.end_date
                }

                form.fields.forEach((item, index) => {
                    state.rules.fields.push(Object.assign({}, state.rule_field))
                    item.options?.forEach(() => {
                        state.rules.fields[index].options.push(Object.assign({}, state.rule_option))
                    })
                })
            }
            
            // change title modal
            if (!props.item.readOnly) {
                state.title = `${!form.id ? 'Tambah' : 'Edit'} `
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            addField,
            removeRowField,
            addFieldOption,
            removeRowFieldOption,
            disabledStartDate,
            disabledEndDate,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
