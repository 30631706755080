<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ADatePicker
                    placeholder="Pilih Periode"
                    style="width:100%;"
                    v-model:value="state.params.date"
                    format="DD MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ASelect
                    v-model:value="state.params.active"
                    show-search
                    allow-clear
                    placeholder="Pilih Status"
                    style="width:100%;"
                    :options="state.statusLists"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12">
            </div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip
                        v-if="hasRoles([ROLE_ADMIN_BK, ROLE_SPC])"
                        title="Tambahkan">
                        <AButton
                            type="primary"
                            @click="btnForm">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip title="Download Excel">
                        <DownloadExcel
                            :url="state.endpoint.current"
                            :params="queryParams()"
                            namefile="Form-Survey"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                row-key="id"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #status="{ text }">
                    <ATag v-if="text" color="#108ee9">Sukses</ATag>
                    <ATag v-else color="grey">Gagal</ATag>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip
                            v-if="hasRoles([ROLE_DISTRIBUTOR])"
                            title="Answer">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnAnswer(record)">
                                <i class="fa fa-file-text" />
                            </AButton>
                        </ATooltip>
                        <ATooltip title="Detail Master Form Survey">
                            <AButton
                                class="button"
                                size="small"
                                @click="() => router.push({
                                    name: 'master.formsurvey.detail',
                                    params: {
                                        id: record.id,
                                    },
                                })">
                                <i class="fa fa-list" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="hasRoles([ROLE_ADMIN_BK, ROLE_SPC])"
                            title="Edit">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnForm(record)">
                                <i class="fe fe-edit" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="hasRoles([ROLE_ADMIN_BK])"
                            title="Hapus">
                            <AButton
                                class="button"
                                size="small"
                                :loading="record.isDelete"
                                @click="btnDelete(record)">
                                <i class="fe fe-trash" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>

        <!-- form -->
        <Form
            v-if="visibleFormModal"
            v-model:visible="visibleFormModal"
            v-model:item="visibleFormItemModal"
            @success="fetchDataList"/>

        <!-- answer -->
        <Answer
            v-if="visibleAnswerModal"
            v-model:visible="visibleAnswerModal"
            v-model:item="visibleAnswerItemModal"
            @success="fetchDataList"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { Modal, message } from 'ant-design-vue'
import apiClient from '@/services/axios'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import FilterRegional from '@/components/filter/FilterRegional'
import Form from './Form'
import Answer from './Answer'
import moment from 'moment'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'
import {
    hasRoles,
    ROLE_ADMIN_BK,
    ROLE_DISTRIBUTOR,
    ROLE_SPC,
} from '@/helpers'

export default defineComponent({
    components: {
        DownloadExcel,
        FilterRegional,
        Form,
        Answer,
    },
    setup() {
        const errorMessage = ref()
        const router = useRouter()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Kode Form',
                    dataIndex: 'code',
                },
                {
                    title: 'Nama Form',
                    dataIndex: 'title',
                },
                {
                    title: 'Regional',
                    dataIndex: 'target',
                    customRender: ({ text }) => text[0]?.regional,
                },
                {
                    title: 'Start Date',
                    dataIndex: 'target',
                    customRender: ({ text }) => text[0]?.start_date,
                },
                {
                    title: 'End Date',
                    dataIndex: 'target',
                    customRender: ({ text }) => text[0]?.end_date,
                },
                {
                    title: 'Status',
                    dataIndex: 'active',
                    slots: { customRender: 'status' },
                },
                {
                    title: 'Action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: {
                current: '/api/formsurvey/master',
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                region: [],
                date: null,
                active: null,
                page: 1,
                "per-page": 10,
            }),
            statusLists: [
                {
                    label: 'Aktif',
                    value: true,
                },
                {
                    label: 'Tidak Aktif',
                    value: false,
                },
            ],
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // btn delete
        const btnDelete = (record) => {
            Modal.confirm({
                title: 'Konfirmasi Hapus',
                content: 'Apakah Anda ingin hapus item tersebut?',
                onOk() {
                    record.isDelete = true

                    apiClient
                        .delete(`${state.endpoint.current}/${record.id}`)
                        .then(() => {
                            fetchDataList()
                            message.success('Berhasil dihapus')
                        })
                        .catch(e => message.error('Gagal menghapus!'))
                        .finally(() => {
                            record.isDelete = false
                        })
                },
                onCancel() { },
            })
        }

        // handle form
        const visibleFormModal = ref(false)
        const visibleFormItemModal = ref(null)
        const btnForm = (item = null, readOnly = false) => {
            visibleFormModal.value = true
            visibleFormItemModal.value = {
                ...item,
                readOnly,
            }
        }

        // handle answer
        const visibleAnswerModal = ref(false)
        const visibleAnswerItemModal = ref(null)
        const btnAnswer = (item = null, readOnly = false) => {
            visibleAnswerModal.value = true
            visibleAnswerItemModal.value = {
                ...item,
                readOnly,
            }
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            router,
            state,
            fetchDataList,
            handleTableChange,
            queryParams,
            errorMessage,
            visibleFormModal,
            visibleFormItemModal,
            visibleAnswerModal,
            visibleAnswerItemModal,
            btnAnswer,
            btnForm,
            btnDelete,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
            ROLE_DISTRIBUTOR,
            ROLE_SPC,
        }
    },
})
</script>
