<template>
    <a-modal
        :visible="visible"
        :title="state.title"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="80%"
        :destroy-on-close="true"
        :footer="null">
    
        <ASkeleton
            :loading="state.isFetching"
            active>
            <AForm
                ref="formRef"
                class="myform"
                :model="form"
                :rules="state.rules"
                @keydown="form.onKeydown($event)"
                @finish="handleOk"
                :wrapper-col="{ span: 14 }"
                :scroll-to-first-error="true">
            
                <!-- answer form -->
                <AFormItem
                    v-for="(item, index) in state.data.fields"
                    :key="index"
                    :label="item.label"
                    label-align="left"
                    :label-col="{ sm: { span: 4 } }"
                    :wrapper-col="{ sm: { span: 24 - 4 } }"
                    :required="_.includes(item.attribute, 'required')"
                    :name="item.name"
                    has-feedback>
            
                    <!-- type text -->
                    <AInput
                        v-if="item.type == 'text'"
                        :disabled="form.readOnly"
                        v-model:value="form[item.name]"
                        style="width:100%;"/>
            
                    <!-- type number -->
                    <AInput
                        v-if="item.type == 'number'"
                        :disabled="form.readOnly"
                        v-model:value="form[item.name]"
                        style="width:100%;"/>
            
                    <!-- type email -->
                    <AInput
                        v-if="item.type == 'email'"
                        type="email"
                        :disabled="form.readOnly"
                        v-model:value="form[item.name]"
                        style="width:100%;"/>
            
                    <!-- type password -->
                    <AInputPassword
                        v-if="item.type == 'password'"
                        :disabled="form.readOnly"
                        v-model:value="form[item.name]"
                        style="width:100%;"/>
            
                    <!-- type file -->
                    <AInput
                        v-if="item.type == 'file'"
                        type="file"
                        :disabled="form.readOnly"
                        v-model:value="form[item.name]"
                        style="width:100%;"/>
            
                    <!-- type textarea -->
                    <ATextarea
                        v-if="item.type == 'textarea'"
                        :disabled="form.readOnly"
                        v-model:value="form[item.name]"
                        style="width:100%;"/>
            
                    <!-- type multiple -->
                    <ASelect
                        v-if="item.type == 'multiple'"
                        :disabled="form.readOnly"
                        :placeholder="`Pilih ${item.label}`"
                        v-model:value="form[item.name]"
                        style="width:100%;">
                        <ASelectOption
                            v-for="(item, index) in item.options"
                            :key="index"
                            :value="item.label">
                            {{ item.description }}
                        </ASelectOption>
                    </ASelect>
            
                </AFormItem>
            
                <ARow
                    v-if="!form.readOnly"
                    class="form-row mt-2"
                    type="flex"
                    justify="end">
                    <ACol>
                        <AFormItem>
                            <a-button
                                type="primary"
                                html-type="submit"
                                :loading="form.busy"
                                :disabled="form.busy">Simpan</a-button>
                        </AFormItem>
                    </ACol>
                </ARow>
            </AForm>
        </ASkeleton>

        <div v-if="state.errors">
            <ASpace>
                <AAlert
                    v-for="(item, index) in state.errors"
                    type="error"
                    :key="index"
                    :message="item"
                    banner
                    closable
                    @close="state.errors = []" />
            </ASpace>
        </div>
    </a-modal>
</template>

<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import apiClient from '@/services/axios'
import Form from 'vform'
import { message } from 'ant-design-vue'
import _ from 'lodash'
import {
    hasRoles,
    ROLE_ADMIN_BK,
} from '@/helpers'

export default defineComponent({
    components: {
        // 
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({}))
        const formRef = ref()

        const state = reactive({
            title: '',
            endpoint: {
                show: `/api/formsurvey/master/${props.item.id}/form-fields`,
                store: '/api/formsurvey/result',
            },
            isFetching: false,
            rules: {},
            data: [],
            errors: [],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const validateRule = (data) => {
            return _.reduce(data, (result, field) => {
                if (_.includes(field.attribute, "required")) {
                    result[field.name] = [
                        {
                            required: true,
                            message: `${field.label} tidak boleh kosong!`,
                        },
                    ]
                }
                return result
            }, {})
        }

        const createDynamicForm = (data) => {
            const formData = {
                id: props.item.id,
                ..._.reduce(data, (result, field) => {
                    result[field.name] = null
                    return result
                }, {}),
            }

            form.update(formData)
        }

        const handleOk = async () => {

            // validation form
            await formRef.value.validate()

            form.post(state.endpoint.store)
                .then(({ data }) => {
                    message.success('Berhasil menyimpan')

                    handleModalCancel()
                    emit('success', data)
                })
                .catch(({ response: { data, status } }) => {
                    if (data) {
                        state.errors = data.errors
                    }
                })
        }

        const fetchById = () => {
            state.isFetching = true

            apiClient.get(state.endpoint.show)
                .then(({ data }) => {
                    // create first dymaic data
                    createDynamicForm(data.options)
                    state.rules = validateRule(data.fields)

                    state.title = data.title
                    state.data = data
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        /**
         * handle func vue
         **/
        onMounted(() => {
            form.readOnly = props.item.readOnly

            // list form survey
            fetchById()
        })

        return {
            _,
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
